import React from 'react'
import { CiLinkedin } from "react-icons/ci";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import logo from "../../assets/image/logo.png";
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='bg-gray-800 text-white w-full p-5 font-poppins'>
      <div className='max-w-7xl mx-auto flex flex-col md:flex-row md:gap-80 justify-between items-start'>
        <div className='flex items-center mb-6 md:mb-0'>
          <img src={logo} alt='logo' className='w-16 h-16 mr-3'/>
          <p className='text-xl font-bold leading-tight'>KNOW YOUR <br/> COLLEGES</p>
        </div>
        <div className='flex md:flex-row justify-between w-full'>
        <div className='mb-6 md:mb-0'>
          <p className='font-bold mb-3'>IMPORTANT LINKS</p>
          <nav className='flex flex-col space-y-2'>
            <Link to='/'>ABOUT</Link>
            <Link to='/'>PRIVACY POLICY</Link>
            <Link to='/'>TERMS OF USE</Link>
            <Link to='/'>CAREERS</Link>
          </nav>
        </div>
        
        <div>
          <p className='font-bold '>CONNECT WITH US</p>
          <div className='flex flex-col md:flex-row'>
            <Link to='https://www.linkedin.com/company/know-your-colleges' className='text-3xl pt-2'><CiLinkedin/></Link>
            <Link to='/' className='text-3xl p-2'><FaWhatsapp/></Link>
            <Link to='https://www.instagram.com/knowyourcolleges_kyc' className='text-3xl p-2'><FaInstagram/></Link>
            <Link to='/' className='text-3xl p-2'><IoMailOutline/></Link>
          </div>
        </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer