import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../assets/image/login_bg.png';
import TextGreen from '../components/home/TextGreen';
import oLetter from '../assets/image/o_white.png';
import { signUp } from '../services/operations/authAPI';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {setProgress} from "../slices/loadingBarSlice";
import OTPInput from 'react-otp-input'


const VerifyOtp = () => {
    const [otp, setOtp] = React.useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading,signupData}= useSelector((state)=>state.auth);


    useEffect(() => {

        if(!signupData){
            navigate('/signup');
        }},[])



    const handleOnSubmit = (e) => {

        e.preventDefault();
        const {email,accountType,confirmPassword,password,lastName,firstName}=signupData;

        dispatch(signUp(accountType,
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            otp,
            navigate));
    }
    


  return(
    loading?(<div className=" h-[100vh] flex justify-center items-center"><div class="custom-loader"></div></div>):
    (
      <div className='flex flex-col md:flex-row min-h-screen'>
  <div className='relative flex-1 '>
      <img src={bg} alt='bg' className='w-full h-full object-cover' />
      <div className='absolute inset-0  flex items-center justify-center'>
          <div className='text-white p-8 max-w-lg'>
              <div className='flex items-start mb-8'>
                  <div className='mr-4 leading-relaxed'>
                      <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl'>Kickstart your college</h1>
                      <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl  flex items-center flex-wrap'>
                          journey with <span className='text-green-500'>Know Your Colleges</span>
                      </h1>
                      
                  </div>
                  <img src={oLetter} alt='logo' className='w-16 h-16 sm:w-20 sm:h-20' />
              </div>
          </div>
      </div>
  </div>
  <div className='flex-1  flex flex-col justify-center items-center p-8 '>
      <div className='mb-10'>
          <h1 className='text-3xl md:text-4xl font-bold mb-5'>
              Create your <span className='text-green-500'>KYC</span> account
          </h1>
      </div>
      <form onSubmit={handleOnSubmit} className='w-full max-w-md'>
      <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    inputStyle="w-[20px] rounded-[8px] border-[1px] border-richblack-500 text-[3rem] text-center"
                    focusStyle="border-[5px] border-red-500"
                    isInputNum={true}
                    shouldAutoFocus={true}
                    containerStyle="flex justify-between gap-4"
                    renderInput={(props) => <input {...props} />}
                    />
          <button type='submit' onClick={()=>{dispatch(setProgress(60))}}
                className='w-full bg-green-500 hover:bg-green-600 mt-5 text-white py-2 px-4 rounded-full'>
              Submit
          </button>
          <div className="mt-6 ">
            
              <Link to="/login" className="text-black hover:text-green-500 font-medium">Already Registered! Login Now</Link>
          </div>
      </form>
      <ToastContainer />
  </div>
</div>)
);
}

export default VerifyOtp