import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CollegeSearch = () => {
  const [colleges, setColleges] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await axios.get(`https://backend-ziyp.onrender.com/api/colleges?page=${page}&limit=20`);
        setColleges(response.data);
      } catch (error) {
        console.error('Error fetching colleges:', error.response || error.message || error);
      }
    };

    fetchColleges();
  }, [page]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredColleges = colleges.filter((college) =>
    college.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCardClick = (collegeId) => {
    navigate(`/college/${collegeId}`);
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search for a college..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full border px-4 py-2 rounded"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredColleges.map((college) => (
          <div
            key={college._id}
            className="border rounded-lg overflow-hidden shadow-md cursor-pointer"
            onClick={() => handleCardClick(college._id)}
          >
            <img
              src={college.image}
              alt={college.name}
              className="w-full h-40 object-cover"
            />
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2">{college.name}</h2>
              <p className="text-gray-600">{college.location}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
          disabled={page === 1}
        >
          Back
        </button>
        <button
          onClick={() => setPage((prev) => prev + 1)}
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CollegeSearch;
