const RankingCard = ({ title, children }) => (
  <div className="bg-black text-white p-4 sm:p-6 rounded-lg shadow-lg h-full flex flex-col">
    <h3 className="text-green-400 font-semibold mb-2 text-lg">{title}</h3>
    <div className="flex-grow">{children}</div>
  </div>
);

const CollegeRanking = ({ collegeRanking }) => {
  return (
    <div className="max-w-4xl py-4 md:mx-4 md:p-4">
      <h2 className="text-3xl font-bold mb-6">College Rankings</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        {collegeRanking.map((ranking, index) => (
          <RankingCard key={index} title={ranking.systemName}>
            {ranking.rank ? (
              <ul className="space-y-1 sm:space-y-2 text-sm sm:text-base">
                <li>{`${ranking.systemName} Rank- ${ranking.rank} (${new Date().getFullYear()})`}</li>
              </ul>
            ) : (
              <p className="text-sm sm:text-base">{ranking.description}</p>
            )}
          </RankingCard>
        ))}
      </div>
    </div>
  );
};

export default CollegeRanking;
