import React from 'react';

const FeeStructure = ({ feeStructure }) => {
  return (
    <div className="max-w-2xl py-4 md:mx-4 md:p-4 bg-customClgDetailsBg">
      <h2 className="text-2xl font-bold mb-4">Fee structure</h2>
      <div className="mb-4 flex flex-row ">
        <p className="text-sm md:text-2sm text-gray-600 mb-2">
          View sources fee structure of official college website
        </p>
        <button className="bg-black text-white mx-2 px-4 py-2 rounded-full">View</button>
      </div>
      <p className="text-sm md:text-2sm text-gray-600 mb-4">
        The fee structure varies depending on the program chosen, semester, accommodation opted for, etc. Generally, it includes tuition fees, hostel fees (if applicable), mess fees, and other miscellaneous charges.
      </p>
      <div className="bg-white text-black rounded-md overflow-hidden shadow-md">
        <h3 className="text-xl font-semibold mb-4 bg-black py-4 px-4 text-green-400">B.Tech Programs</h3>
        <div className="space-y-2 p-4">
          {feeStructure.map((program, index) => (
            <div key={index} className="flex justify-between items-center py-4 px-2 border-b last:border-b-0">
              <span className="text-sm">{program.programName}</span>
              <span className="text-sm">INR {program.fees.toLocaleString()}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeeStructure;
