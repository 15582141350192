import {useContext} from 'react';
import {SocketContext} from '../Context/SocketContext.jsx';

const CreateRoom = () => {
    const {socket} = useContext(SocketContext);
    const initRoom = () => {
        socket.emit('create-room');
    }
    return (
        
            <button 
           
            onClick={initRoom} 
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' >
                
                Create Room
            </button>
        
    );
}

export default CreateRoom;