import { useState } from 'react';
import Countup from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

export const Counter = () => {
    const [countOn, setCountOn] = useState(false);
  return (
    <ScrollTrigger onEnter={()=> setCountOn(true)} onExit={()=> setCountOn(false)}>
          <div className="flex flex-col sm:flex-row mt-16 md:mt-[100px] ml-2 sm:ml-8">
          <div className="flex flex-col flex-1 text-4xl sm:text-5xl md:text-6xl font-poppins font-bold ml-2 sm:ml-6 border-b-4 sm:border-b-0 sm:border-r-4 border-gray-500 pb-4 sm:pb-0 sm:pr-4 md:pr-[45px] md:pl-[45px] items-center mb-8 sm:mb-0">
         <span className='text-green-500'>   <Countup end={120} duration={5} start={countOn ? 0 : null} />+ </span>
            <p className="text-base sm:text-lg md:text-xl  mt-3 font-semibold">
              colleges
            </p>
          </div>
          <div className="flex flex-col flex-1 text-4xl sm:text-5xl md:text-6xl font-poppins font-bold border-b-4 sm:border-b-0 sm:border-r-4 border-gray-500 pb-4 sm:pb-0 sm:pr-4 md:pr-[45px] md:pl-[45px] items-center mb-8 sm:mb-0">
          <span className='text-green-500'>   <Countup end={200} duration={5} start={countOn ? 0 : null} />+ </span>
          <p className="text-base sm:text-lg md:text-xl mt-3 font-semibold">
              students
            </p>
          </div>
          <div className="flex flex-col flex-1 text-4xl sm:text-5xl md:text-6xl items-center font-poppins font-bold">
          <span className='text-green-500'>   <Countup end={80} duration={5} start={countOn ? 0 : null} />+ </span>
          <p className="text-base sm:text-lg md:text-xl mt-3 font-semibold">
              mentors
            </p>
          </div>
        </div>

    </ScrollTrigger>
    
  )
}
