import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom"
import { SocketContext } from "../Context/SocketContext";
import { UserFeedPlayer } from "../components/UserFeedPlayer";


export const Room = () => {
    const {id} = useParams();
    const {socket, user, stream, peersOnCall}= useContext(SocketContext);
  
    useEffect(()=> {
       // console.log(socket);
        if (user){ 
            console.log(user._id);
            socket.emit('joined-room', {roomId: id, userId: user._id})
           
        }
        
    },[id, user, socket]);
  return (
    <div>
    <h2>Room: {id}</h2>
    <div>
        <h3>Your Feed</h3>
        <UserFeedPlayer stream={stream} />
    </div>
    <div>
        <h3>Other Users' Feeds</h3>
        {Object.entries(peersOnCall).map(([userId, { stream }]) => (
            <UserFeedPlayer key={userId} stream={stream} />
        ))}
    </div>
</div>
  )
}
