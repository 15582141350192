import React from 'react'
import { Link } from 'react-router-dom'

function SessionRequest() {
    return (
        <div>
            <div className="min-h-screen bg-zinc-100  flex">
                <div className="w-1/5 py-4 flex flex-col justify-between" style={{ backgroundColor: '#001E2B' }}>
                    <div>
                        {/* <img src="https://placehold.co/100x100" alt="Logo" className="mb-8" crossorigin="anonymous" /> */}
                        <ul className="space-y-4 text-white flex flex-col">
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/moderatordash/sprofile'>Alloted Students</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/moderatordash/squery'>Students Queries</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/moderatordash/srequest'>Session Requests</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/moderatordash/supcoming'>Upcoming Sessions</Link>
                        </ul>
                    </div>
                    <button className="text-white mt-8">↩ Log Out</button>
                </div>
                <div className="w-4/5 px-4 py-3">
                    <div className="flex justify-between items-center mb-5 bg-white py-3 px-2 rounded-xl">
                        <Link to='/moderatordash' className="text-2xl font-bold">Dashboard</Link>
                        <div className="relative">
                            <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 rounded-full bg-white  border border-zinc-300  focus:outline-none" />
                            
                        </div>
                    </div>
                    <div className='flex gap-8'>
                        <div className="mb-8 w-2/3 h-fit px-5 bg-white py-3 rounded-xl">
                            <div className="p-6">
                                <div className="">
                                    <h2 className="text-2xl font-bold mb-4">Session Requests</h2>
                                    <div className="space-y-4">

                                        <div className="flex items-center justify-between bg-card p-4 rounded-lg shadow">
                                            <div className="flex items-center">
                                                <img src="https://placehold.co/48x48" alt="Profile picture of Sahil Panwar" className="rounded-full mr-4" />
                                                <div>
                                                    <h3 className="text-lg font-bold">CS v/s Core Branch</h3>
                                                    <p className="text-muted-foreground">Request By <span className="text-foreground">Sahil Panwar[IZETM2385]</span></p>
                                                    <p className="text-muted-foreground">Senior Needed <span className="text-foreground">Yash Gaur</span></p>
                                                </div>
                                            </div>
                                            <div className="flex space-x-2">
                                                <button className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600">Schedule Session</button>
                                                <button className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600">Decline</button>
                                            </div>
                                        </div>

                                        <div className="bg-card p-4 rounded-lg shadow-md flex items-center justify-between">
                                            <div className="flex items-center space-x-4">
                                                <img src="https://placehold.co/100x100" alt="session illustration" className="w-16 h-16 rounded-full" />
                                                <div>
                                                    <h3 className="text-lg font-bold">CS v/s Core Branch</h3>
                                                    <p className="text-muted-foreground">Request By <span className="text-foreground">Sahil Panwar[IZETM2385]</span></p>
                                                    <p className="text-muted-foreground">Senior Needed <span className="text-foreground">Yash Gaur</span></p>
                                                </div>
                                            </div>
                                            <div className="flex space-x-2">
                                                <button className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600">Schedule Session</button>
                                                <button className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600">Decline</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-10">
                            <div className=" bg-white  p-4 rounded-lg shadow" style={{ width: '25rem' }}>
                                <div className="flex items-center space-x-4">
                                    <img src="https://placehold.co/80x80" alt="Profile Picture" className="w-20 h-20 rounded-full" crossorigin="anonymous" />
                                    <div>
                                        <h3 className="text-lg font-semibold">Aditya Pandey</h3>
                                        <p className="text-sm text-zinc-500">Senior Moderator</p>
                                        <div className="flex space-x-1 text-green-500">
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <p><strong>Join Date:</strong> 22 Nov '23</p>
                                    <p><strong>Session Count:</strong> 10+</p>
                                </div>
                            </div>
                            <div className="" style={{ width: '25rem' }}>
                                <div className="bg-white  p-4 rounded-lg shadow">
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="text-lg font-semibold text-green-500">Notifications</h3>
                                        <span className="text-zinc-500">29 May 2024</span>
                                    </div>
                                    <div className="border-t border-zinc-200 dark:border-zinc-700 pt-4">
                                        <div className="flex items-center space-x-4 mb-4">
                                            <img src="https://placehold.co/40x40" alt="Notification Avatar" className="w-10 h-10 rounded-full" crossorigin="anonymous" />
                                            <div>
                                                <p className="text-sm font-semibold">Mr. Sarthak Gupta</p>
                                                <p className="text-xs text-zinc-500">COO</p>
                                                <p className="text-xs">We have a meet at 5:00 PM</p>
                                            </div>
                                            <span className="text-xs text-zinc-500 ml-auto">1hr ago</span>
                                        </div>
                                        <hr />
                                        <div className="flex items-center space-x-4 mb-4">
                                            <img src="https://placehold.co/40x40" alt="Notification Avatar" className="w-10 h-10 rounded-full" crossorigin="anonymous" />
                                            <div>
                                                <p className="text-sm font-semibold">Mr. Sarthak Gupta</p>
                                                <p className="text-xs text-zinc-500">COO</p>
                                                <p className="text-xs">We have a meet at 5:00 PM</p>
                                            </div>
                                            <span className="text-xs text-zinc-500 ml-auto">1hr ago</span>
                                        </div>
                                        <hr />
                                        <div className="flex items-center space-x-4 mb-4">
                                            <img src="https://placehold.co/40x40" alt="Notification Avatar" className="w-10 h-10 rounded-full" crossorigin="anonymous" />
                                            <div>
                                                <p className="text-sm font-semibold">Mr. Sarthak Gupta</p>
                                                <p className="text-xs text-zinc-500">COO</p>
                                                <p className="text-xs">We have a meet at 5:00 PM</p>
                                            </div>
                                            <span className="text-xs text-zinc-500 ml-auto">1hr ago</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionRequest