// src/components/ContactInfo.js
import React from 'react';

const ContactInfo = ({ formData, setFormData, prevStep, nextStep }) => {
    const { Best, Worst, Skill } = formData;

    return (
        <div className="w-full rounded">
            <div style={{ height: '30rem' }}>
                <h2 className="text-2xl mb-4">Contact Information</h2>
                <div className="mb-4">
                    <label className="block text-gray-700">Best Things about your college:</label>
                    <input
                        required
                        type="email"
                        value={Best}
                        onChange={(e) => setFormData({ ...formData, Best: e.target.value })}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="mb-4"> 
                    <label className="block text-gray-700">Worst Things about your college:</label>
                    <input
                        required
                        type="tel"
                        value={Worst}
                        onChange={(e) => setFormData({ ...formData, Worst: e.target.value })}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Skills:</label>
                    <input
                        required
                        type="tel"
                        value={Skill}
                        onChange={(e) => setFormData({ ...formData, Skill: e.target.value })}
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>
            <div className="flex justify-between">
                <button onClick={prevStep} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700">Back</button>
                <button onClick={nextStep} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">Next</button>
            </div>
        </div>
    );
};

export default ContactInfo;
