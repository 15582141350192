

const InfrastructureCard = ({photo, title, description}) => (
    <div className="flex flex-row w-full my-3">
        <img className="rounded-full w-12 h-12" src={photo} alt="icon" />
        <div className="flex flex-col">
            <p className="font-bold text-2sm p-1">{title}</p>
            <p className="text-sm">{description}</p>
        </div>
    </div>
)


export default InfrastructureCard
