import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/image/senior.jpg';
import img2 from '../assets/image/program2.jpeg';
import img3 from '../assets/image/program3.jpeg';
import aim from '../assets/image/aim.png';

function Programs() {
    return (
        <div>
            <div className="bg-background text-foreground p-8 space-y-8">
                {/* <Link to='/' className="text-muted-foreground mb-4">← Back</Link> */}
                <div className='flex flex-col space-y-10'>
                    <div className="flex flex-col md:flex-row justify-evenly items-center my-10 space-y-10 md:space-y-0">
                        <div className="space-y-5">
                            <h2 className="text-4xl md:text-6xl font-bold text-primary" style={{ color: '#22C55E' }}>Seniors Connect</h2>
                            <ul className="list-disc text-lg md:text-xl list-inside space-y-2">
                                <li>Connect 1-On-1 with seniors of preferred college</li>
                                <li>Get Real time insight of college</li>
                                <li>Get clarity of college options</li>
                                <li>Get the best college at your rank</li>
                            </ul>
                            <div>
                                <Link to='/connect' className="bg-white text-green-500 border-green-500 border-2 rounded-lg px-4 py-2 hover:bg-secondary/80">Get started</Link>
                            </div>
                        </div>
                        <img src={img1} alt="Person video calling a senior" className="rounded-lg shadow-lg w-full md:w-1/2 max-w-md md:max-w-none" crossorigin="anonymous" />
                    </div>

                    <div className="flex flex-col md:flex-row justify-evenly items-center my-10 space-y-10 md:space-y-0">
                        <img src={img2} alt="College building" className="rounded-lg shadow-lg w-full md:w-1/2 max-w-md md:max-w-none" crossorigin="anonymous" />
                        <div className="space-y-5">
                            <h2 className="text-4xl md:text-6xl font-bold text-primary" style={{ color: '#22C55E' }}>Get your college</h2>
                            <ul className="list-disc text-lg md:text-xl list-inside space-y-2">
                                <li>Connect 1-On-1 with preferred Seniors</li>
                                <li>Get Personalized Counsellor</li>
                                <li>Personalized Support Throughout the Process</li>
                                <li>Get the help in choice filling</li>
                                <li>Get 24/7 doubt support</li>
                                <li>Get the best college for yourself</li>
                            </ul>
                            <div>
                                <Link to='/college' className="bg-white text-green-500 border-green-500 border-2 rounded-lg px-4 py-2 hover:bg-secondary/80">Enroll now</Link>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row justify-evenly items-center my-10 space-y-10 md:space-y-0">
                        <div className="space-y-4 ">
                            <h2 className="text-4xl md:text-6xl font-bold text-primary" style={{ color: '#22C55E' }}>Level Up to IITian</h2>
                            <p className="text-muted-foreground flex items-center space-x-2">
                                <img style={{ width: '2.5rem' }} src={aim} alt="coming-soon" crossorigin="anonymous" />
                                <span>Coming soon</span>
                            </p>
                            <ul className="list-disc text-lg md:text-xl list-inside space-y-2">
                                <li>Connect 1-on-1 with Top IITians</li>
                                <li>Learn the IITian way of pursuing B-tech</li>
                                <li>Level up your skill for top placement</li>
                                <li>Get the insight of top IITs</li>
                            </ul>
                            <div className="flex space-x-2">
                                <input type="email" placeholder="Enter your email" className="border border-input rounded-sm px-4 py-2 w-full md:w-auto" />
                                <button className="bg-white text-green-500 border-green-500 border-2 rounded-lg px-4 py-2 hover:bg-secondary/80">Notify me</button>
                            </div>
                        </div>
                        <img src={img3} alt="Illustration of IIT" className="rounded-lg shadow-lg w-full md:w-1/2 max-w-md md:max-w-none" crossorigin="anonymous" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Programs;
