import clubImg from '../../assets/image/club.png'

import React from 'react';

// ClubCard Component
const ClubCard = ({ photo, title, description }) => (
  <div className="flex flex-col p-0 rounded-lg shadow-lg">
    <img className="m-0 rounded" src={photo} alt="club" />
    <div className="p-4">
      <p className="font-bold text-sm">{title}</p>
      <p className="text-sm">{description}</p>
    </div>
  </div>
);

// Club Component
const Club = ({ clubs }) => {
  return (
    <div className="max-w-2xl py-4 md:p-4">
      <h2 className="text-3xl font-bold mb-6">Clubs & Societies</h2>
      <div className="grid grid-cols-2 gap-4 sm:gap-6">
        {clubs && clubs.length > 0 ? (
          clubs.map((club, index) => (
            <ClubCard
              key={index}
              photo={club.photo || clubImg} // Fallback for missing photo
              title={club.title}
              description={club.description || 'No description available'}
            />
          ))
        ) : (
          <p>No clubs and societies available at this time.</p>
        )}
      </div>
    </div>
  );
};

export default Club;
