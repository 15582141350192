import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Search } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import logo from "../../assets/image/logo.png";
import { logout } from '../../services/operations/authAPI';

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(state => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setShowOptions(searchTerm.length > 0);
  };

  const handleOptionClick = () => {
    navigate('/collegeDetails');
    setSearchTerm(''); // Clear search term after navigation
    setShowOptions(false); // Hide options after navigation
  };

  return (
    <nav className='bg-white font-poppins md:max-h-fit'>
      <div className='p-4 sm:p-6 lg:p-8'>
        <div className='flex sm:flex-row md:flex-col justify-between max-h-fit'>
          <div className='flex items-center text-sm md:text-xl'>
            <button onClick={toggleMenu} className='mr-2 inline-flex items-center justify-center p-2 rounded-md hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden'>
              <span className='sr-only'>Open main menu</span>
              <Menu className='block h-6 w-6' aria-hidden='true' />
            </button>
            <div className='flex flex-row w-[100%]'>
              <Link to='/' className='flex items-center'>
                <img src={logo} alt='logo' className='w-8 h-8 mr-2' />
                <p className='font-bold text-lg md:text-2xl '>KYC</p>
              </Link>
              <div className='ml-8 flex-row hidden md:block w-[100%]'>
                <button className='items-center'>
                  <Search className='h-6 w-6 text-gray-400' />
                </button>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search colleges, courses, locations..."
                  className="pl-2 pr-8 py-3 rounded-md w-[50%] bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-400"
                />
              </div>
            </div>
          </div>

          <div className='flex flex-row mt-2 justify-between'>
            <div className='hidden md:flex items-center space-x-4 text-md'>
              <Link to='/' className='text-gray-600 block px-3 py-2'>
                <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>Home</div>
              </Link>
              <Link to='/programs' className='text-gray-600 h block px-3 py-2'>
                <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>Programs</div>
              </Link>
              <Link to='/searchCollege' className='text-gray-600 block px-3 py-2'>
                <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>College Search</div>
              </Link>
              <Link to='/' className='text-gray-600 block px-3 py-2'>
                <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>College Events</div>
              </Link>
              <Link to='/predictor' className='text-gray-600 block px-3 py-2'>
                <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>College Predictor</div>
              </Link>
            </div>

            <div className='flex items-center'>
              <button className='md:hidden m-1'>
                <Search className='h-6 w-6 text-gray-400' />
              </button>

              {
                token == null && (
                  <button className='border-green-500 border-2 text-green-500 md:bg-green-500 hover:bg-green-600 hover:text-white md:text-white p-1 md:py-2 md:px-4 rounded-full text-xs mr-2'>
                    <Link to='/mentor'>Become a mentor</Link>
                  </button>
                )
              }
              {
                token == null && (
                  <button className='bg-green-500 hover:bg-green-600 text-white p-2 md:py-2 md:px-4 rounded-full text-xs'>
                    <Link to='/login'>Login</Link>
                  </button>
                )
              }
              {
                token != null && (
                  <button onClick={() => { dispatch(logout(navigate)) }}
                    className='bg-green-500 hover:bg-green-600 text-white p-2 md:py-2 md:px-4 rounded-full text-xs'>
                    <Link to='/'>Logout</Link>
                  </button>
                )
              }
            </div>
          </div>
        </div>
      </div>

      {showOptions && (
        <div className='absolute top-16 left-0 right-0 bg-white border border-gray-300 rounded-md shadow-lg z-10'>
          <ul className='py-1'>
            <li onClick={handleOptionClick} className='px-4 py-2 cursor-pointer hover:bg-gray-100'>
              VIT
            </li>
            {/* Add more options here based on search results */}
          </ul>
        </div>
      )}

      {isOpen && (
        <div className='md:hidden'>
          <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
            <Link to='/' className='text-gray-600 block px-3 py-2'>
              <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>Home</div>
            </Link>
            <Link to='/programs' className='text-gray-600 h block px-3 py-2'>
              <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>Programs</div>
            </Link>
            <Link to='/searchCollege' className='text-gray-600 block px-3 py-2'>
              <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>College Search</div>
            </Link>
            <Link to='/' className='text-gray-600 block px-3 py-2'>
              <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>College Compare</div>
            </Link>
            <Link to='/predictor' className='text-gray-600 block px-3 py-2'>
              <div className='max-w-fit p-2 hover:bg-green-400 rounded-md transition ease-in-out delay-100 hover:-translate-x-1'>College Predictor</div>
            </Link>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar;
