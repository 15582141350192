import React from 'react';
import { ArrowDownToLine } from 'lucide-react';

const Courses = ({ program }) => {
  return (
    <li className="flex flex-row justify-between items-center p-2 border-b last:border-b-0">
      <span className="text-sm md:text-base">{program.name}</span>
      <ArrowDownToLine className="w-5 h-5 md:w-6 md:h-6" />
    </li>
  );
};

export default Courses;
