import React from 'react'
import collegeImage from '../../assets/image/collegeImage.png'

const CollegeCard = ({ img, name = "INDIAN INSTITUTE OF TECHNOLOGY", loc , link}) => {
    return (
        <div className='flex flex-col bg-white w-80 h-120 pb-10 mt-4 mr-3  rounded-lg shadow-md'>
            <a target='_blank' href={link}>
                <div>
                    <img className='w-full overflow-hidden h-[10rem]' src={(img) ? img : collegeImage} alt="College" />
                </div>
                <div>
                    <p className='font-poppins text-2sm md:text-2xl font-bold ml-4 mt-8 pr-3'>{name}</p>
                </div>
                <div>
                    <p className='font-poppins text-gray-600 mt-3 ml-4'>{loc}</p>
                </div>
                <div className='flex flex-row mt-5  p-4 text-gray-600'>
                    <p className='bg-gray-200 font-poppins  p-2 mr-3 rounded'>B.Tech</p>
                    <p className='bg-gray-200 font-poppins p-2 mr-3 rounded'>M.Tech</p>
                    <p className='bg-gray-200 font-poppins p-2 rounded '>Phd</p>
                </div>
            </a>
        </div>
    )
}

export default CollegeCard