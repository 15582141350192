import { MessageCircleMore } from "lucide-react";

const ViewMentors = ({ name, program, photo }) => {
  return (
    <div className="flex-col bg-white rounded-md p-4 w-full mt-4">
      <div className="flex justify-between items-center px-1 m-1">
        <img className="rounded-full w-10 h-10" src={photo} alt="mentor" />
        <div className="flex-col text-sm">
          <h4>{name}</h4>
          <p>{program}</p>
        </div>
        <MessageCircleMore />
      </div>
    </div>
  );
};

export default ViewMentors;
