const CoursesCapacity = ({ courses }) => {
  if (!courses || courses.length === 0) {
    return <p className="text-gray-500">No course information available.</p>;
  }

  return (
    <div>
      {courses.map((course, index) => (
        <div key={index}>
          <hr className="my-2" />
          <p className="text-xl text-gray-800 font-bold py-2">{course.category}</p>
          <ul className="md:text-base text-xl text-gray-700 list-none py-2">
            {course.programs.map((program, idx) => (
              <li key={idx} className="flex justify-between py-1">
                <p>{program.name}</p>
                <p>{program.capacity}</p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default CoursesCapacity;
