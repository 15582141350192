import React from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import bg from '../assets/image/login_bg.png';
import TextGreen from '../components/home/TextGreen';
import oLetter from '../assets/image/o_white.png';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { sendOtp } from "../services/operations/authAPI";
import { setSignupData } from "../slices/authSlice";
import { ACCOUNT_TYPE } from "../utils/constants";
import {setProgress} from "../slices/loadingBarSlice";


const SignUp = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
  
    // student or instructor
    const [accountType, setAccountType] = useState(ACCOUNT_TYPE.STUDENT)
  
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    })

    const { firstName, lastName, email, password, confirmPassword } = formData
  
    // Handle input fields, when some value changes
    const handleOnChange = (e) => {
      setFormData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }))
    }
  
    // Handle Form Submission
    const handleOnSubmit = (e) => {
      e.preventDefault()
  
      if (password !== confirmPassword) {
        toast.error("Passwords Do Not Match")
        return
      }
      const signupData = {
        ...formData,
        accountType,
      }
  
      // Setting signup data to state
      // To be used after otp verification
      dispatch(setSignupData(signupData))
      // Send OTP to user for verification
      dispatch(sendOtp(formData.email, navigate))
  
      // Reset
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      })
      setAccountType(ACCOUNT_TYPE.STUDENT)
    }
  
    // data to pass to Tab component


  return(
      <div className='flex flex-col md:flex-row min-h-screen'>
  <div className='relative flex-1 '>
      <img src={bg} alt='bg' className='w-full h-full object-cover' />
      <div className='absolute inset-0  flex items-center justify-center'>
          <div className='text-white p-8 max-w-lg'>
              <div className='flex items-start mb-8'>
                  <div className='mr-4 leading-relaxed'>
                      <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl'>Kickstart your college</h1>
                      <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl  flex items-center flex-wrap'>
                          journey with <span className='text-green-500'>Know Your Colleges</span>
                      </h1>
                      
                  </div>
                  <img src={oLetter} alt='logo' className='w-16 h-16 sm:w-20 sm:h-20' />
              </div>
          </div>
      </div>
  </div>
  <div className='flex-1  flex flex-col justify-center items-center p-8 '>
      <div className='mb-10'>
          <h1 className='text-3xl md:text-4xl font-bold mb-5'>
              Create your <span className='text-green-500'>KYC</span> account
          </h1>
      </div>
      <form onSubmit={handleOnSubmit} className='w-full max-w-md'>
            <input
                type='text'
                name="firstName"
                required = {true}
                placeholder='First Name'
                value={firstName}
                onChange={handleOnChange}
                className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5' />
            <input type="text" 
            placeholder='Last Name'
            name="lastName"
            required = {true}
            value={lastName}
            onChange={handleOnChange}
            className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5' />
            

          <input
              type='email'
              required = {true}
              name="email"
              placeholder='Email'
              value={email}
              onChange={handleOnChange}
              className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5'
          />
          <input
              type='password'
              required = {true}
              name="password"
              placeholder='Password'
              value={password}
              onChange={handleOnChange}
              className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5'
          />
            <input
                type='password'
                required = {true}
                name="confirmPassword"
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={handleOnChange}
                className='w-full my-2 px-3 py-2 border border-gray-300 rounded-md mb-5' />
          <button type='submit' nClick={()=>{dispatch(setProgress(60))}}
                className='w-full bg-green-500 hover:bg-green-600 mt-5 text-white py-2 px-4 rounded-full'>
              SignUp
          </button>
          <div className="mt-6 ">
            
              <Link to="/login" className="text-black hover:text-green-500 font-medium">Already Registered! Login Now</Link>
          </div>
      </form>
      <ToastContainer />
  </div>
</div>
);
}

export default SignUp