import React, { useState } from "react";
import axios from "axios";

const AddCollege = () => {
  const [collegeData, setCollegeData] = useState({
    name: "",
    category: "",
    type: "",
    image: "",
    location: "",
    courses: [],
    feeStructure: [],
    collegeRanking: [],
    placement: "",
    infrastructure: [],
    club: [],
    dressCode: "",
    reviews: [],
    mentors: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCollegeData({ ...collegeData, [name]: value });
  };

  const handleArrayChange = (index, arrayName, fieldName, value) => {
    const updatedArray = [...collegeData[arrayName]];
    updatedArray[index] = { ...updatedArray[index], [fieldName]: value };
    setCollegeData({ ...collegeData, [arrayName]: updatedArray });
  };

  const handleNestedArrayChange = (outerIndex, innerIndex, arrayName, nestedArrayName, fieldName, value) => {
    const updatedArray = [...collegeData[arrayName]];
    const nestedArray = [...updatedArray[outerIndex][nestedArrayName]];
    nestedArray[innerIndex] = { ...nestedArray[innerIndex], [fieldName]: value };
    updatedArray[outerIndex][nestedArrayName] = nestedArray;
    setCollegeData({ ...collegeData, [arrayName]: updatedArray });
  };

  const addArrayField = (arrayName, defaultObject) => {
    setCollegeData({
      ...collegeData,
      [arrayName]: [...collegeData[arrayName], defaultObject],
    });
  };

  const addNestedArrayField = (index, arrayName, nestedArrayName, defaultObject) => {
    const updatedArray = [...collegeData[arrayName]];
    updatedArray[index][nestedArrayName] = [
      ...updatedArray[index][nestedArrayName],
      defaultObject,
    ];
    setCollegeData({ ...collegeData, [arrayName]: updatedArray });
  };

  const removeArrayField = (arrayName, index) => {
    const updatedArray = collegeData[arrayName].filter((_, i) => i !== index);
    setCollegeData({ ...collegeData, [arrayName]: updatedArray });
  };

  const removeNestedArrayField = (outerIndex, arrayName, nestedArrayName, innerIndex) => {
    const updatedArray = [...collegeData[arrayName]];
    updatedArray[outerIndex][nestedArrayName] = updatedArray[outerIndex][nestedArrayName].filter(
      (_, i) => i !== innerIndex
    );
    setCollegeData({ ...collegeData, [arrayName]: updatedArray });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://backend-ziyp.onrender.com/api/colleges",
        collegeData
      );
      alert("College added successfully!");
    } catch (error) {
      console.error("Error adding college:", error);
      alert("Failed to add college.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-8 max-w-4xl mx-auto space-y-6 bg-gray-50 shadow-md rounded-md"
    >
      <h2 className="text-3xl font-bold text-center">Add College</h2>

      {/* Name */}
      <div>
        <label className="block font-semibold">Name</label>
        <input
          type="text"
          name="name"
          value={collegeData.name}
          onChange={handleChange}
          className="border p-2 w-full rounded"
          placeholder="Enter college name"
        />
      </div>

      {/* Category */}
      <div>
        <label className="block font-semibold">Category</label>
        <select
          name="category"
          value={collegeData.category}
          onChange={handleChange}
          className="border p-2 w-full rounded"
        >
          <option value="">Select</option>
          <option value="Engineering">Engineering</option>
          <option value="Medical">Medical</option>
        </select>
      </div>

      {/* Type */}
      <div>
        <label className="block font-semibold">Type</label>
        <select
          name="type"
          value={collegeData.type}
          onChange={handleChange}
          className="border p-2 w-full rounded"
        >
          <option value="">Select</option>
          <option value="Government">Government</option>
          <option value="Private">Private</option>
        </select>
      </div>

      {/* Image */}
      <div>
        <label className="block font-semibold">Image URL</label>
        <input
          type="text"
          name="image"
          value={collegeData.image}
          onChange={handleChange}
          className="border p-2 w-full rounded"
          placeholder="Enter image URL"
        />
      </div>

      {/* Location */}
      <div>
        <label className="block font-semibold">Location</label>
        <input
          type="text"
          name="location"
          value={collegeData.location}
          onChange={handleChange}
          className="border p-2 w-full rounded"
          placeholder="Enter college location"
        />
      </div>

      {/* Courses */}
      <div>
        <label className="block font-semibold">Courses</label>
        {collegeData.courses.map((course, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="text"
              placeholder="Category"
              value={course.category || ""}
              onChange={(e) =>
                handleArrayChange(i, "courses", "category", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <label className="block font-semibold mt-2">Programs</label>
            {course.programs.map((program, j) => (
              <div key={j} className="space-y-2">
                <input
                  type="text"
                  placeholder="Program Name"
                  value={program.name || ""}
                  onChange={(e) =>
                    handleNestedArrayChange(i, j, "courses", "programs", "name", e.target.value)
                  }
                  className="border p-2 w-full rounded"
                />
                <input
                  type="number"
                  placeholder="Capacity"
                  value={program.capacity || ""}
                  onChange={(e) =>
                    handleNestedArrayChange(i, j, "courses", "programs", "capacity", e.target.value)
                  }
                  className="border p-2 w-full rounded"
                />
                <button
                  type="button"
                  onClick={() => removeNestedArrayField(i, "courses", "programs", j)}
                  className="text-red-600 mt-2"
                >
                  Remove Program
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNestedArrayField(i, "courses", "programs", { name: "", capacity: "" })}
              className="text-blue-600"
            >
              Add Program
            </button>
            <button
              type="button"
              onClick={() => removeArrayField("courses", i)}
              className="text-red-600 mt-2"
            >
              Remove Course
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => addArrayField("courses", { category: "", programs: [] })}
          className="text-blue-600"
        >
          Add Course
        </button>
      </div>

      {/* Fee Structure */}
      <div>
        <label className="block font-semibold">Fee Structure</label>
        {collegeData.feeStructure.map((fee, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="text"
              placeholder="Program Name"
              value={fee.programName || ""}
              onChange={(e) =>
                handleArrayChange(i, "feeStructure", "programName", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <input
              type="number"
              placeholder="Fees"
              value={fee.fees || ""}
              onChange={(e) =>
                handleArrayChange(i, "feeStructure", "fees", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <button
              type="button"
              onClick={() => removeArrayField("feeStructure", i)}
              className="text-red-600 mt-2"
            >
              Remove Fee Structure
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => addArrayField("feeStructure", { programName: "", fees: 0 })}
          className="text-blue-600"
        >
          Add Fee Structure
        </button>
      </div>

      {/* College Ranking */}
      <div>
        <label className="block font-semibold">College Ranking</label>
        {collegeData.collegeRanking.map((ranking, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="text"
              placeholder="System Name"
              value={ranking.systemName || ""}
              onChange={(e) =>
                handleArrayChange(i, "collegeRanking", "systemName", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <input
              type="number"
              placeholder="Rank"
              value={ranking.rank || ""}
              onChange={(e) =>
                handleArrayChange(i, "collegeRanking", "rank", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <textarea
              placeholder="Description"
              value={ranking.description || ""}
              onChange={(e) =>
                handleArrayChange(i, "collegeRanking", "description", e.target.value)
              }
              className="border p-2 w-full rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => removeArrayField("collegeRanking", i)}
              className="text-red-600 mt-2"
            >
              Remove Ranking
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => addArrayField("collegeRanking", { systemName: "", rank: 0, description: "" })}
          className="text-blue-600"
        >
          Add Ranking
        </button>
      </div>

      {/* Placement */}
      <div>
        <label className="block font-semibold">Placement</label>
        <textarea
          name="placement"
          value={collegeData.placement}
          onChange={handleChange}
          className="border p-2 w-full rounded"
          placeholder="Enter placement details"
        ></textarea>
      </div>

      {/* Infrastructure */}
      <div>
        <label className="block font-semibold">Infrastructure</label>
        {collegeData.infrastructure.map((infra, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="text"
              placeholder="Title"
              value={infra.title || ""}
              onChange={(e) =>
                handleArrayChange(i, "infrastructure", "title", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <textarea
              placeholder="Description"
              value={infra.description || ""}
              onChange={(e) =>
                handleArrayChange(i, "infrastructure", "description", e.target.value)
              }
              className="border p-2 w-full rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => removeArrayField("infrastructure", i)}
              className="text-red-600 mt-2"
            >
              Remove Infrastructure
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => addArrayField("infrastructure", { title: "", description: "" })}
          className="text-blue-600"
        >
          Add Infrastructure
        </button>
      </div>

      {/* Clubs */}
      <div>
        <label className="block font-semibold">Clubs</label>
        {collegeData.club.map((club, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="text"
              placeholder="Title"
              value={club.title || ""}
              onChange={(e) =>
                handleArrayChange(i, "club", "title", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <textarea
              placeholder="Description"
              value={club.description || ""}
              onChange={(e) =>
                handleArrayChange(i, "club", "description", e.target.value)
              }
              className="border p-2 w-full rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => removeArrayField("club", i)}
              className="text-red-600 mt-2"
            >
              Remove Club
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => addArrayField("club", { title: "", description: "" })}
          className="text-blue-600"
        >
          Add Club
        </button>
      </div>

      {/* Dress Code */}
      <div>
        <label className="block font-semibold">Dress Code</label>
        <textarea
          name="dressCode"
          value={collegeData.dressCode}
          onChange={handleChange}
          className="border p-2 w-full rounded"
          placeholder="Enter dress code details"
        ></textarea>
      </div>

      {/* Reviews */}
      <div>
        <label className="block font-semibold">Reviews</label>
        {collegeData.reviews.map((review, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="number"
              placeholder="Star Rating"
              value={review.star || ""}
              onChange={(e) =>
                handleArrayChange(i, "reviews", "star", e.target.value)
              }
              className="border p-2 w-full rounded"
              min={1}
              max={5}
            />
            <textarea
              placeholder="Description"
              value={review.description || ""}
              onChange={(e) =>
                handleArrayChange(i, "reviews", "description", e.target.value)
              }
              className="border p-2 w-full rounded"
            ></textarea>
            <button
              type="button"
              onClick={() => removeArrayField("reviews", i)}
              className="text-red-600 mt-2"
            >
              Remove Review
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => addArrayField("reviews", { star: 0, description: "" })}
          className="text-blue-600"
        >
          Add Review
        </button>
      </div>

      {/* Mentors */}
      <div>
        <label className="block font-semibold">Mentors</label>
        {collegeData.mentors.map((mentor, i) => (
          <div key={i} className="space-y-2 border-b pb-2 mb-2">
            <input
              type="text"
              placeholder="Name"
              value={mentor.name || ""}
              onChange={(e) =>
                handleArrayChange(i, "mentors", "name", e.target.value)
              }
              className="border p-2 w-full rounded"
            />
            <input
              type="text"
              placeholder="Program"
              value={mentor.program || ""}
              onChange={(e) =>
                handleArrayChange(i, "mentors", "program", e.target.value)
                  }
                  className="border p-2 w-full rounded"
                />
                  <input
                  type="text"
                  placeholder="Photo URL"
                  value={mentor.photo || ""}
                  onChange={(e) =>
                    handleArrayChange(i, "mentors", "photo", e.target.value)
                  }
                  className="border p-2 w-full rounded"
                />
                <button
                  type="button"
                  onClick={() => removeArrayField("mentors", i)}
                  className="text-red-600 mt-2"
                >
                  Remove Mentor
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayField("mentors", { name: "", program: "", photo: "" })}
              className="text-blue-600"
            >
              Add Mentor
            </button>
          </div>
    
          {/* Submit Button */}
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded font-bold hover:bg-blue-600"
          >
            Submit
          </button>
        </form>
      );
    };
    
    export default AddCollege;
    
