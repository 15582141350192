import React, { useState } from 'react';

const InquiryForm = () => {
  // State variables to hold form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    currentStatus: '12th', // Default selected current status
    query: 'admission_support', // Default selected query option
    otherQuery: ''
  });

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // You can process or submit the form data here
    // Reset form after submission (if needed)
    setFormData({
      name: '',
      email: '',
      mobile: '',
      currentStatus: '12th',
      query: 'admission_support',
      otherQuery: ''
    });
  };

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className=" p-4 flex flex-col">
      <form onSubmit={handleSubmit}>
      <div className='flex flex-row '>
            {/* Name input */}
        <div className="mb-6 flex flex-row flex-1 mr-5 ">
          <label htmlFor="name" className=" mr-2 text-md font-bold text-black">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="pl-2 text-sm border-b w-7/12  bg-gray-200  border-black focus:outline-none"
            
            required
          />
        </div>

        {/* Email input */}
        <div className="mb-6 flex flex-row flex-1">
          <label htmlFor="email" className="block mr-2 text-md font-bold text-black">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="pl-2 text-sm border-b  w-7/12 bg-gray-200  border-black focus:outline-none"
            required
          />
        </div>
      </div>
        
        <div className="mb-4 flex flex-row mt-4">
                    {/* Mobile input */}
        <div className="mb-4 flex flex-row flex-1">
          <label htmlFor="mobile" className="block text-md font-bold text-black pr-2">
            Mobile No
          </label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="pl-2 text-sm border-b w-7/12 mb-2 bg-gray-200  border-black focus:outline-none"
            required
          />
        </div>

        {/* Current Status options */}
        <div className="mb-4 flex flex-row flex-1">
          <label className="block text-md font-bold text-black mb-2 pr-2 mr-4">Current Status</label>
          <div className="flex items-center space-x-4 mb-2">
            {/* Radio button for 12th */}
            <div>
              <input
                type="radio"
                id="status_12th"
                name="currentStatus"
                value="12th"
                checked={formData.currentStatus === '12th'}
                onChange={handleChange}
                className="form-radio text-indigo-600"
              />
              <label htmlFor="status_12th" className="ml-2 text-sm text-gray-700">
                12th
              </label>
            </div>

            {/* Radio button for Dropper */}
            <div>
              <input
                type="radio"
                id="status_dropper"
                name="currentStatus"
                value="dropper"
                checked={formData.currentStatus === 'dropper'}
                onChange={handleChange}
                className="form-radio text-indigo-600"
              />
              <label htmlFor="status_dropper" className="ml-2 text-sm text-gray-700">
                Dropper
              </label>
            </div>
          </div>
        </div>
        </div>


        {/* Query options */}
        <div className="mb-4 flex flex-row">
          <label className="block text-md font-bold text-black mr-4 mb-2">Query</label>
          <div className="flex items-center space-x-4">
            {/* Radio button options for Query */}
            {/* Add radio buttons for query options */}
            <div>
              <input
                type="radio"
                id="query_admission"
                name="query"
                value="admission_support"
                checked={formData.query === 'admission_support'}
                onChange={handleChange}
                className="form-radio text-indigo-600"
              />
              <label htmlFor="query_admission" className="ml-2 text-sm text-gray-700">
                Admission Support
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="query_placement"
                name="query"
                value="placement_stats"
                checked={formData.query === 'placement_stats'}
                onChange={handleChange}
                className="form-radio text-indigo-600"
              />
              <label htmlFor="query_placement" className="ml-2 text-sm text-gray-700">
                Placement Stats
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="query_college"
                name="query"
                value="college_options"
                checked={formData.query === 'college_options'}
                onChange={handleChange}
                className="form-radio text-indigo-600"
              />
              <label htmlFor="query_college" className="ml-2 text-sm text-gray-700">
                College Options
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="query_other"
                name="query"
                value="other"
                checked={formData.query === 'other'}
                onChange={handleChange}
                className="form-radio text-indigo-600"
              />
              <label htmlFor="query_other" className="ml-2 text-sm text-gray-700">
                Other (Specify)
              </label>
            </div>
          </div>
        </div>

        {/* Text field for other query */}
        {formData.query === 'other' && (
          <div className="flex flex-row mb-4">
            <label htmlFor="otherQuery" className="text-md font-bold text-black pr-2">Specify Other</label>
            <input
              type="text"
              id="otherQuery"
              name="otherQuery"
              value={formData.otherQuery}
              onChange={handleChange}
              className="pl-2 text-sm border-b w-7/12 bg-gray-200 border-black focus:outline-none"
              required
            />
          </div>
        )}

        {/* Submit button */}
        <div className="flex items-center justify-center h-full">
        <button
          type="submit"
            className="text-lg flex items-center font-poppins px-4 py-2 text-white bg-black rounded-full justify-center"
                    >
          Submit
        </button>
        </div>
        
      </form>
    </div>
  );
};

export default InquiryForm;
