import React, { useEffect, useRef } from "react";

export const UserFeedPlayer = ({ stream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }

        return () => {
            // Cleanup function
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
        };
    }, [stream]);  // Only re-run if stream changes

    const handleCanPlay = () => {
        videoRef.current.play().catch(error => {
            console.error("Error attempting to play video:", error);
        });
    };

    return (
        <video 
            ref={videoRef}
            muted={true}
            autoPlay
            playsInline  // For better mobile support
            onCanPlay={handleCanPlay}
            style={{ maxWidth: '100%', height: 'auto' }}
        />
    );
};