import React, { useState } from "react";
import axios from "axios";

const Admin = () => {
  const [formData, setFormData] = useState({
    name: "",
    image: [],
    location: "",
    courses: "",
    feeStructure: "",
    collegeRanking: "",
    placement: "",
    infrastructure: "",
    club: "",
    dressCode: "",
    reviews: "",
    mentors: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:6000/api/colleges", formData);
      alert("College added successfully");
    } catch (error) {
      console.error(error);
      alert("Error adding college");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <h1 className="text-2xl font-bold">Admin Panel</h1>
      <div className="grid grid-cols-1 gap-4">
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <input
          type="text"
          name="location"
          placeholder="Location"
          value={formData.location}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="courses"
          placeholder="Courses (comma-separated)"
          value={formData.courses}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="feeStructure"
          placeholder="Fee Structure"
          value={formData.feeStructure}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <input
          type="number"
          name="collegeRanking"
          placeholder="Ranking"
          value={formData.collegeRanking}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="placement"
          placeholder="Placement"
          value={formData.placement}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="infrastructure"
          placeholder="Infrastructure"
          value={formData.infrastructure}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="club"
          placeholder="Club"
          value={formData.club}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="dressCode"
          placeholder="Dress Code"
          value={formData.dressCode}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="reviews"
          placeholder="Reviews (comma-separated)"
          value={formData.reviews}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <textarea
          name="mentors"
          placeholder="Mentors (comma-separated)"
          value={formData.mentors}
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <button type="submit" className="p-2 bg-blue-500 text-white rounded">
          Add College
        </button>
      </div>
    </form>
  );
};

export default Admin;
