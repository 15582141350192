import college from "../../assets/image/collegeImage.png";
import { ArrowRight } from 'lucide-react';
import ViewMentors from "./ViewMentors";
import ListedCourses from "./ListedCourses";
import CoursesCapacity from "./CoursesCapacity";
import mentor from "../../assets/image/mentor.jpeg";
import FeeStructure from "./FeeStructure";
import CollegeRanking from "./CollegeRanking";
import InfrastructureCard from "./InfrastructureCard";
import Club from "./Club";
import check from '../../assets/image/check.png'
import DisplayImages from "./DisplayImages";
import sportsImg from "../../assets/image/sportsImg.png";
import SportsClub from "./SportsClub";
import mentorDefault from "../../assets/image/profile.png"
import DressCode from "./DressCode";
import Reviews from "./Reviews";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';



const Details = () => {

  const { collegeId } = useParams();
  const [college, setCollege] = useState(null);

  useEffect(() => {
    const fetchCollegeDetails = async () => {
      try {
        
        const response = await axios.get(`https://backend-ziyp.onrender.com/api/colleges/${collegeId}`);
        setCollege(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error('Error fetching college details:', error.response || error.message || error);
      }
    };

    fetchCollegeDetails();
  }, [collegeId]);

  if (!college) {
    return <div>Loading...</div>;
  }


  return (

    
    
    <div className=" w-screen  px-6  justify-between items-start  bg-customClgDetailsBg">
      <div className="flex flex-col  md:flex-row justify-between items-center w-full">
        <div className="flex flex-col m-4 w-full md:w-2/3">
          <div className="container mx-auto px-4 py-8">
            <h3 className="text-4xl font-bold text-gray-700 py-1">
            {college.name}
            </h3>
            <p className="text-2xs text-gray-500 py-2">
              {college.location}
            </p>
            <button className="rounded-full bg-black text-sm text-white py-1 px-3">
              + Add to list
            </button>
          </div>

          <div className="flex flex-col px-4 w-full">
            <div className="flex gap-2 py-1">
              <img
                className="w-1/3 rounded-md object-cover h-40"
                src={college}
                alt="College Image"
              />
              <img
                className="w-2/3 rounded-md object-cover h-40"
                src={college}
                alt="College Image"
              />
            </div>
            <div className="flex gap-2 py-1">
              <img
                className="w-2/3 rounded-md object-cover h-40"
                src={college}
                alt="College Image"
              />
              <img
                className="w-1/3 rounded-md object-cover h-40"
                src={college}
                alt="College Image"
              />
            </div>
          </div>

          <div className="bg-green-400 p-4 mx-4 my-5 rounded-lg flex items-center justify-between w-2/3">
            <div className="h-8 bg-pink-100">fkee</div>
            <p className="text-sm">
              Explore the full campus album of {college.name}
            </p>
            <button className="text-sm bg-white text-black font-bold rounded-md p-2">
              View more
            </button>
          </div>

          <div className="m-4">
            <h3 className="text-xl font-bold w-full">Courses Offered</h3>
            {/* Map over the courses and pass them to ListedCourses */}
            {college.courses.map((courseCategory, index) => (
              <ListedCourses key={index} category={courseCategory} />
            ))}
          </div>
        </div>

        <div className="w-full md:w-1/4 flex flex-col items-center md:items-end mx-auto">
          <div className="md:mt-10">
            <img
              className="rounded-md w-full hidden md:block"
              src={college}
              alt="College Image"
            />

            <div className="flex border-1 border-black bg-white rounded-md p-3 mt-3 w-full">
              <div>
                <p className="md:text-2xl text-3xl font-bold">
                  Course capacity (seats)
                </p>
                <CoursesCapacity courses = {college.courses}/>
                <hr className="my-2" />
              </div>
            </div>
          </div>

          <div className="mt-5 w-full">
            <h3 className="text-xl font-bold">Featured Mentor</h3>
            {college.mentors.map((mentor, index) => (
              <ViewMentors
                key={index}
                name={mentor.name}
                program={mentor.program}
                photo={mentor.photo || mentorDefault} // Use default photo if none is provided
              />
            ))}
            <div className="flex flex-row w-full justify-between items-center bg-green-400 rounded-md mt-4 p-4">
              <div className="flex wrap relative">
                <img
                  className="w-12 h-12 rounded-full border-4 border-white absolute top-1/2 left-1/2 -translate-x-1/7 -translate-y-1/2"
                  src={mentorDefault}
                  alt="mentor"
                />
                <img className="w-12 h-12 rounded-full border-4 border-white" src={mentorDefault} alt="mentor" />
              </div>
              <p className="font-semibold">View more mentors</p>
              <ArrowRight />
            </div>
          </div>

        </div>
      </div>
      <FeeStructure feeStructure={college.feeStructure}/>
      <CollegeRanking collegeRanking={college.collegeRanking}/>

      <div className="py-4 md:m-4 md:p-4">
        <h3 className="text-2xl md:text-xl font-bold">Infrastructure</h3>
        <div className="flex flex-col my-4">
          {college.infrastructure && college.infrastructure.length > 0 ? (
            college.infrastructure.map((infra, index) => (
              <InfrastructureCard
                key={index}
                photo={check}
                title={infra.title}
                description={infra.description}
              />
            ))
          ) : (
            <p>No infrastructure details available.</p>
          )}
        </div>
      </div>


      <div className="py-4 md:m-4 md:p-4 ">
      <DisplayImages/>
      </div>

       
 

      <div className="py-4 md:m-4 ">
      <Club clubs={college.club}/>
      </div>
    
      <div className="my-4 md:m-4 md:p-4">
          <h3 className="text-2xl md:text-xl font-bold">Dress Code</h3>
          <DressCode
            para={college.dressCode}
          />
       </div>

      <div className="max-w-4xl md:m-4 p-4 ">
        <h3 className="text-2xl md:text-xl font-bold py-2">Fests</h3>
        <p className="font-semibold py-2">Riviera </p>
        <p className="text-2sm md:text-sm py-2">Riviera is VIT's annual techno-management fest, considered one of the largest student-organized festivals in Asia. It features various competitions, workshops, shows, and performances</p>
        <div className="py-4">
      <DisplayImages/>
      </div>
      </div>


      
      <div className="max-w-4xl md:mx-4 md:px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Reviews</h2>
        <div className="space-y-4">
          {college.reviews && college.reviews.length > 0 ? (
            college.reviews.map((review, index) => (
              <Reviews
                key={index}
                name={`User ${index + 1}`} // Placeholder for user name
                rating={review.star * 2} // Convert 5-star scale to 10-point scale
                review={review.description || 'No review provided'}
              />
            ))
          ) : (
            <p className="text-gray-500">No reviews available.</p>
          )}
        </div>
      </div>

     

     
    </div>
    
    
  );
};

export default Details;
