import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import VerifyOTP from "./pages/VerifyOtp.jsx";
import Mentor from './pages/Mentor'
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Dashboard from "./pages/Moderator/Dashboard";
import MentorDashboard from "./pages/Mentor/Dashboard";
import StudentDashboard from "./pages/Students/Dashboard";
import Programs from "./pages/Programs";
import Connect from "./pages/Connect";
import College from "./pages/College";
import StudentProfile from "./pages/Moderator/StudentProfile";
import StudentQuery from "./pages/Moderator/StudentQuery";
import SessionRequest from "./pages/Moderator/SessionRequest";
import UpcomingSession from "./pages/Moderator/UpcomingSession";
import MSessionRequest from "./pages/Mentor/SessionRequest";
import ScheduledSessions from "./pages/Mentor/ScheduledSessions";
import ScheduledSession from "./pages/Students/ScheduleSession";
import SessionHistory from "./pages/Mentor/SessionHistory";
import PaymentHistory from "./pages/Mentor/PaymentHistory";
import ModeratorAllotted from "./pages/Students/ModeratorAllotted";
import SavedCollege from "./pages/Students/SavedCollege";
import PredicatedCollege from "./pages/Students/PredicatedCollege";
import ChoiceFilling from "./pages/Students/ChoiceFilling";
import ProfileEdit from "./pages/Students/ProfileEdit";
import CollegeDetails from "./pages/CollegeDetails/Details.jsx";
import VideoConfrence from "./components/VideoConfrence";
import CollegePredictor from "./pages/CollegePredictor";
import LaunchingSoon from "./pages/LaunchingSoon.jsx";
import CreateRoom from "./components/CreateRoom";
import { Room } from "./pages/Room";
import {SocketProvider} from './Context/SocketContext.jsx'
import CollegeList from "./components/CollegeList.jsx"
import CollegeDetail from "./components/CollegeDetails.jsx";
import Admin from "./components/Admin.jsx";
import AddCollege from "./pages/AddCollege.jsx";
import CollegeSearch from "./pages/CollegeSearch.jsx";

function App() {
  return (
      
    
    <div>


      <Navbar />
      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<h1>Not Found</h1>} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/verify-email" element={<VerifyOTP/> } />
        <Route exact path="/mentor" element={<Mentor />} />
        <Route exact path="/moderatordash" element={<Dashboard />} />
        <Route exact path="/mentordash" element={<MentorDashboard />} />
        <Route exact path="studentdash/" element={<StudentDashboard />} />
        <Route exact path="/programs" element={<Programs />} />
        <Route exact path="/connect" element={<Connect />} />
        <Route exact path="/college" element={<College />} />
        <Route exact path="moderatordash/sprofile" element={<StudentProfile />} />
        <Route exact path="moderatordash/squery" element={<StudentQuery />} />
        <Route exact path="moderatordash/srequest" element={<SessionRequest />} />
        <Route exact path="moderatordash/supcoming" element={<UpcomingSession />} />
        <Route exact path="mentordash/scheduledsessions" element={<ScheduledSessions />} />
        <Route exact path="mentordash/sessionhistory" element={<SessionHistory />} />
        <Route exact path="mentordash/paymenthistory" element={<PaymentHistory />} />
        <Route exact path="mentordash/sessionrequest" element={<MSessionRequest />} />
        <Route exact path="studentdash/allotted" element={<ModeratorAllotted />} />
        <Route exact path="studentdash/scheduled" element={<ScheduledSession />} />
        <Route exact path="studentdash/saved" element={<SavedCollege />} />
        <Route exact path="studentdash/predicted" element={<PredicatedCollege />} />
        <Route exact path="studentdash/choice" element={<ChoiceFilling />} />
        <Route exact path="studentdash/profile" element={<ProfileEdit />} />
        <Route exact path="/confrence" element={<VideoConfrence />} />
        <Route exact path="/predictor" element={<CollegePredictor />} />
        <Route exact path="/LS" element={<LaunchingSoon />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/addCollege" element={<AddCollege/>}/>
        <Route path="/searchCollege" element={<CollegeSearch/>}/>

        <Route path="/college/:collegeId" element={<CollegeDetails />} />
        <Route exact path="/createRoom" element={
          <SocketProvider>
          <CreateRoom />
          </SocketProvider>
          } />
        <Route exact path="/room/:id" element={
          <SocketProvider>
            <Room />
          </SocketProvider>
        } />
              </Routes>
      <Footer />


    </div>
  );
}

export default App;
