import React from 'react'
import Launchingsoon from '../assets/image/launchingSoon.png'
const LaunchingSoon = () => {
  return (
    <div className='flex sm:flex-col md:flex-col lg:flex-row'>
        <div className='flex flex-col p-8' >
          <p className='font-semibold text-5xl leading-relaxed'>Launching Soon : <span className='text-green-500'>Know Your College!🚀</span></p>
          <p className='mt-4 font-normal text-xl leading-relaxed'>We're working hard to bring you more amazing features on "Know Your College." Stay tuned for upcoming tools and resources that will help you explore, compare, 
            and make informed decisions about your college options.</p>
          <p className='mt-6 font-normal text-xl leading-relaxed'>Keep an eye out for updates – exciting new features are on their way!</p>
          <div className='flex flex-row mt-8 '>
            <input className='border border-gray-500 p-1 md:p-3 sm:w-80 md:w-96' type="email" name="" id="" placeholder='Enter your email'/>
            <button className='bg-green-500 text-sm md:text-md text-white p-2 md:p-3 hover:bg-green-600'>Notify me</button>
          </div>

        </div>
        <div>
          <img className='w-full h-full object-cover' src={Launchingsoon} alt="launching Soon" />
        </div>
    </div>
  )
}

export default LaunchingSoon
