import React from 'react'
import { Link } from 'react-router-dom'

function ModeratorAllotted() {
    return (
        <div>
            <div className="min-h-screen bg-zinc-100  flex">
                <div className="w-1/5 py-4 flex flex-col justify-between" style={{ backgroundColor: '#001E2B' }}>
                    <div>
                        <ul className="space-y-4 text-white flex flex-col">
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/allotted'>Moderator Allotted</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/scheduled'>Scheduled Sessions</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/saved'>Saved Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/predicted'>Predicted Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/choice'>Choice Filling</Link>
                        </ul>
                    </div>
                    <button className="text-white mt-8">↩ Log Out</button>
                </div>
                <div className="w-4/5 px-4 py-3">
                    <div className="flex justify-between items-center mb-5 bg-white py-3 px-2 rounded-xl">
                        <Link to='/studentdash' className="text-2xl font-bold">Dashboard</Link>
                        <div className="relative">
                            <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 rounded-full bg-white  border border-zinc-300  focus:outline-none" />

                        </div>
                    </div>
                    <div className='flex gap-8'>
                        <div className="mb-8 w-2/3 h-fit px-5 bg-white py-3 rounded-xl">
                            <div className="p-6 bg-card text-card-foreground rounded-lg shadow-md">
                                <div className="flex items-center space-x-4">
                                    <img src="https://placehold.co/100x100" alt="User profile picture" className="w-24 h-24 rounded-full" />
                                    <div>
                                        <span className="bg-green-500 text-green-100 px-2 py-1 rounded-lg text-sm">Moderator</span>
                                        <h2 className="text-2xl font-semibold mt-2">Aditya Pandey</h2>
                                        <p className="text-muted-foreground">Alloted Date <span className="text-foreground">17 May 2024</span></p>
                                        <p className="text-muted-foreground">Rating <span className="text-foreground">⭐⭐⭐</span></p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-2xl font-semibold">Queries</h3>
                                        <button className="bg-green-500 text-green-100 px-4 py-2 rounded-lg">Raise a Query</button>
                                    </div>
                                    <div className="mt-4 bg-card p-4 rounded-lg shadow-inner">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <h4 className="font-bold text-2xl">How we can check the home state?</h4>
                                                <p className="text-muted-foreground">Ans - Class 12 State is your Home State</p>
                                            </div>
                                            <p className="text-muted-foreground">Asked by <span className="text-foreground font-bold">Rohan Kumar</span></p>
                                        </div>
                                        <hr className="my-4 border-muted" />
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <h4 className="font-bold text-2xl">How we can check the home state?</h4>
                                                <p className="text-muted-foreground">Ans - Class 12 State is your Home State</p>
                                            </div>
                                            <p className="text-muted-foreground">Asked by <span className="text-foreground font-bold">Rohan Kumar</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <h3 className="text-xl font-semibold">Upcoming Sessions</h3>
                                    <div className="mt-4 bg-card p-4 rounded-lg shadow-inner">
                                        <h4 className="font-bold text-2xl my-2">Choice filling for Jac Delhi</h4>
                                        <p className="text-muted-foreground">Date and Time <span className="text-foreground font-bold">20 May 2024 and 8:00 AM</span></p>
                                        <p className="text-muted-foreground">Mentor <span className="text-foreground font-bold">Shreya Singh</span></p>
                                        <div className="flex space-x-4 mt-4">
                                            <button className="bg-green-500 text-green-100 px-4 py-2 rounded-lg">Join</button>
                                            <button className="bg-muted text-muted-foreground px-4 py-2 rounded-lg border-2 border-green-500 text-green-500">Set a reminder</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div style={{ width: '30rem' }} className="max-w-sm mx-auto bg-card text-card-foreground p-6 rounded-lg shadow-lg bg-white" >
                                <div className="flex flex-col" >
                                    <img src="https://placehold.co/80x80" className='w-1/2 m-auto mb-10 rounded-full' alt="" />
                                    <div className="flex flex-col gap-7">
                                        <p className="font-semibold">Name - <span className="font-normal">Aditya Pandey</span></p>
                                        <p className="font-semibold">College - <span className="font-normal">ABESEC</span></p>
                                        <p className="font-semibold">Email - <span className="font-normal">abc@gmail.com</span></p>
                                        <p className="font-semibold">Phone - <span className="font-normal">98XXXXXXXX</span></p>
                                        <p className="font-semibold">Branch - <span className="font-normal">Computer Science</span></p>
                                        <p className="font-semibold">Current Year - <span className="font-normal">2</span></p>
                                        <p className="font-semibold">Session Counts - <span className="font-normal">10</span></p>
                                        <p className="font-semibold">Ratings -
                                            <span className="font-normal text-green-500">
                                                ★★★
                                            </span>
                                        </p>
                                    </div>
                                    <Link to='/studentdash/profile' className="text-white bg-green-500 mt-4 bg-primary text-primary-foreground py-2 px-4 rounded-lg hover:bg-primary/80">Edit Profile</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModeratorAllotted