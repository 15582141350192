import { ADD_PEER, REMOVE_PEER } from "../Actions/PeerActions";
export const peerReducer = (state, action) => {
    switch(action.type) {
        case ADD_PEER:
            console.log("Adding peer:", action.payload);
            return {
                ...state,
                [action.payload.userId]: {
                    stream: action.payload.stream
                }
            };
        case REMOVE_PEER:
            console.log("Removing peer:", action.payload.userId);
            const { [action.payload.userId]: removedPeer, ...restOfState } = state;
            return restOfState;
        default:
            return state;
    }
};