import React from 'react';
import Courses from './Courses';

const ListedCourses = ({ category }) => {
  return (
    <div className="w-full my-3">
      <div className="rounded-lg w-full bg-white overflow-hidden shadow-md my-4 md:my-0">
        <h3 className="text-base bg-green-400 p-2">{category.category} Programs</h3>
        <ul className="list-none w-full bg-white">
          {/* Map over the programs and display them together */}
          {category.programs.map((program, index) => (
            <Courses key={index} program={program} />
          ))}
        </ul>
      </div>
      <hr className="my-4 border-gray-300" />
    </div>
  );
};

export default ListedCourses;
