import React from 'react'
import { Link } from 'react-router-dom'

function ScheduleSession() {
    return (
        <div>
            <div className="min-h-screen bg-zinc-100  flex">
                <div className="w-1/5 py-4 flex flex-col justify-between" style={{ backgroundColor: '#001E2B' }}>
                    <div>
                        <ul className="space-y-4 text-white flex flex-col">
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/allotted'>Moderator Allotted</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/scheduled'>Scheduled Sessions</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/saved'>Saved Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/predicted'>Predicted Colleges</Link>
                            <Link className='duration-200 py-5 px-3 hover:bg-green-600' to='/studentdash/choice'>Choice Filling</Link>
                        </ul>
                    </div>
                    <button className="text-white mt-8">↩ Log Out</button>
                </div>
                <div className="w-4/5 px-4 py-3">
                    <div className="flex justify-between items-center mb-5 bg-white py-3 px-2 rounded-xl">
                        <Link to='/studentdash' className="text-2xl font-bold">Dashboard</Link>
                        <div className="relative">
                            <input type="text" placeholder="Search" className="pl-10 pr-4 py-2 rounded-full bg-white  border border-zinc-300  focus:outline-none" />

                        </div>
                    </div>
                    <div className='flex gap-8'>
                        <div className="mb-8 w-2/3 h-fit px-5 bg-white py-3 rounded-xl">
                            <div className="p-6 bg-background text-foreground">
                                <div className="mb-6">
                                    <h2 className="text-2xl font-bold mb-4">Upcoming Sessions</h2>
                                    <div className="space-y-4">
                                        <div className="p-4 border rounded-lg bg-card text-card-foreground">
                                            <h3 className="text-2xl mb-4 font-semibold">Choice filling for Jac Delhi</h3>
                                            <p className="text-muted-foreground">
                                                Date and Time <span className="text-foreground font-bold">20 May 2024 and 8:00 AM</span>
                                            </p>
                                            <p className="text-muted-foreground">
                                                Mentor <span className="text-foreground font-bold">Shreya Singh</span>
                                            </p>
                                            <div className="flex space-x-4 mt-2">
                                                <button className="bg-green-500 text-white px-4 py-2 rounded-lg">Join</button>
                                                <button className="border border-green-500 text-green-500 px-4 py-2 rounded-lg">Recommended</button>
                                            </div>
                                        </div>

                                        <div className="p-4 border rounded-lg bg-card text-card-foreground">
                                            <h3 className="text-2xl mb-4 font-semibold">Choice filling for JOSAA</h3>
                                            <p className="text-muted-foreground">
                                                Date and Time <span className="text-foreground font-bold">20 May 2024 and 8:00 AM</span>
                                            </p>
                                            <p className="text-muted-foreground">
                                                Mentor <span className="text-foreground font-bold">Shreya Singh</span>
                                            </p>
                                            <div className="flex space-x-4 mt-2">
                                                <button className="bg-green-500 text-white px-4 py-2 rounded-lg">Join</button>
                                                <button className="border border-green-500 text-green-500 px-4 py-2 rounded-lg">Not Recommended</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-2xl font-bold">Recorded Sessions</h2>
                                        <a href="#" className="text-primary">
                                            See All
                                        </a>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="p-4 border-2 border-gray-300 rounded-lg bg-card text-card-foreground w-fit h-full flex flex-col gap-5">
                                            <div className="flex justify-center mb-4">
                                                <img undefinedhidden="true" alt="play-button" src="https://openui.fly.dev/openui/100x100.svg?text=▶️" />
                                            </div>
                                            <p className="">Day 20</p>
                                            <h3 className="text-xl font-semibold ">JOSAA NIT'S Colleges</h3>
                                            <p className=" text-muted-foreground">
                                                Mentor <span className="text-foreground font-bold">Shreya Singh</span>
                                            </p>
                                            <p className=" text-muted-foreground">
                                                Date and Time <span className="text-foreground font-bold">20 May 2024 and 8:00 AM</span>
                                            </p>
                                        </div>

                                        <div className="p-4 border-2 border-gray-300 rounded-lg bg-card text-card-foreground w-fit h-full flex flex-col gap-5">
                                            <div className="flex justify-center mb-4">
                                                <img undefinedhidden="true" alt="play-button" src="https://openui.fly.dev/openui/100x100.svg?text=▶️" />
                                            </div>
                                            <p className="">Day 20</p>
                                            <h3 className="text-xl font-semibold ">JOSAA NIT'S Colleges</h3>
                                            <p className=" text-muted-foreground">
                                                Mentor <span className="text-foreground font-bold">Shreya Singh</span>
                                            </p>
                                            <p className=" text-muted-foreground">
                                                Date and Time <span className="text-foreground font-bold">20 May 2024 and 8:00 AM</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div style={{ width: '30rem' }} className="max-w-sm mx-auto bg-card text-card-foreground p-6 rounded-lg shadow-lg bg-white" >
                                <div className="flex flex-col" >
                                    <img src="https://placehold.co/80x80" className='w-1/2 m-auto mb-10 rounded-full' alt="" />
                                    <div className="flex flex-col gap-7">
                                        <p className="font-semibold">Name - <span className="font-normal">Aditya Pandey</span></p>
                                        <p className="font-semibold">College - <span className="font-normal">ABESEC</span></p>
                                        <p className="font-semibold">Email - <span className="font-normal">abc@gmail.com</span></p>
                                        <p className="font-semibold">Phone - <span className="font-normal">98XXXXXXXX</span></p>
                                        <p className="font-semibold">Branch - <span className="font-normal">Computer Science</span></p>
                                        <p className="font-semibold">Current Year - <span className="font-normal">2</span></p>
                                        <p className="font-semibold">Session Counts - <span className="font-normal">10</span></p>
                                        <p className="font-semibold">Ratings -
                                            <span className="font-normal text-green-500">
                                                ★★★
                                            </span>
                                        </p>
                                    </div>
                                    <Link to='/studentdash/profile' className="text-white bg-green-500 mt-4 bg-primary text-primary-foreground py-2 px-4 rounded-lg hover:bg-primary/80">Edit Profile</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleSession